import "./Customer.css";
import { adalApiFetch, authContext } from "../../adalConfig";
import { SiteGroupList } from "./SiteGroupList";
import CustomerForm from "./CustomerForm";

import React, { Component } from "react";
import { withFormik } from "formik";
// import Yup from "yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { find, pull, without, findIndex } from "lodash";
import ModalDialog from "../../utils/ModalDialog";

const getCustomerForm = handleSubmit =>
    withFormik({
        mapPropsToValues: props => ({
            name: props.customer.name ? props.customer.name : "",
            nameExtension: props.customer.nameExtension ? props.customer.nameExtension : "",
            contactEmail: props.customer.contactEmail
                ? props.customer.contactEmail
                : "",
            contactName: props.customer.contactName
                ? props.customer.contactName
                : "",
            contactPhone: props.customer.contactPhone
                ? props.customer.contactPhone
                : "",
            streetAddress: props.customer.streetAddress
                ? props.customer.streetAddress
                : "",
            additionalStreetAddress: props.customer.additionalStreetAddress
                ? props.customer.additionalStreetAddress
                : "",
            city: props.customer.city ? props.customer.city : "",
            postCode: props.customer.postCode ? props.customer.postCode : "",
            country: props.customer.country ? props.customer.country : "FI",
            vatnumber: props.customer.vatnumber ? props.customer.vatnumber : "",
            currencyId: props.customer.currencyId
                ? props.customer.currencyId
                : "",
            paymentTermNetDays: props.customer.paymentTermNetDays ? props.customer.paymentTermNetDays : 14,
            reference: props.customer.reference ? props.customer.reference : "",
            customerCode: props.customer.customerCode ? props.customer.customerCode : "",
            quickBooksID: props.customer.quickBooksID ? props.customer.quickBooksID : "",
            netSuiteID: props.customer.netSuiteID ? props.customer.netSuiteID : ""
        }),
        validationSchema: yup.object().shape({
            name: yup.string().required("Name is required!"),
            currencyId: yup.number().required("Currency is required!"),
            customerCode: yup.string().length(5).matches(/0[0-9]{4}/, 'Five digits (0-9): 0????, leave empty if not known')
        }),
        handleSubmit,
        displayName: "BasicForm", // helps with React DevTools
    })(CustomerForm);

export class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: {},
            currencies: [],
            loading: this.props.match.params.customerId ? true : false,
            isAddGroupButtonDisabled: false,
            newSiteGroupIndex: -1,
            accountManagers: [],
            selectedAccountManager: '',
            modalIsOpen: false,
        };

        this.setNewSiteForGroup = this.setNewSiteForGroup.bind(this);
        this.removeSiteFromGroup = this.removeSiteFromGroup.bind(this);
        this.onGroupInvoiceIntervalChanged = this.onGroupInvoiceIntervalChanged.bind(
            this
        );
        this.onGroupInvoiceDateChanged = this.onGroupInvoiceDateChanged.bind(
            this
        );
        this.onGroupInvoicePeriodStartDateChanged = this.onGroupInvoicePeriodStartDateChanged.bind(
            this
        );
        this.onGroupPaymentTermChanged = this.onGroupPaymentTermChanged.bind(
            this
        );
        this.removeSiteGroup = this.removeSiteGroup.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.Form = getCustomerForm(this.saveCustomer);

        this.closeModal = this.closeModal.bind(this);
        this.onAccountManagerSelected = this.onAccountManagerSelected.bind(this);
        this.assignToAccountManager = this.assignToAccountManager.bind(this)
    }

    componentDidMount() {
        if (this.props.match.params.customerId) {
            let customerId = this.props.match.params.customerId;

            const url = "/api/Customers/" + customerId;
            this.setState({ loading: true, isUpdate: true });

            adalApiFetch(fetch, url, {})
                .then(function(response) {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error(
                            "Error: " +
                            response.status +
                            " with customer: " +
                            customerId
                        );
                    }
                })
                .then(data => {
                    this.setState({
                        customer: data,
                    });

                    this.loadCurrencies();
                    this.loadAccountManagers();
                })
                .catch(function(error) {
                    console.log(error);
                    toast.error(error.message, {
                        autoClose: 3500,
                    });
                });
        } else {
            this.loadCurrencies();
            this.loadAccountManagers();
        }
    }

    loadAccountManagers() {
        adalApiFetch(fetch, "/api/Users", {})
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Insufficient permissions"); // Current user is not an admin
                }
            })
            .then(data => {
                this.setState({ accountManagers: data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    loadCurrencies() {
        adalApiFetch(fetch, "/api/Currencies", {})
            .then(function(response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(
                        "Error: " +
                        response.status +
                        " with fetching currencies"
                    );
                }
            })
            .then(data => {
                this.setState({
                    currencies: data,
                    loading: false,
                });
            });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="customer-view">
                    <h1>Customer</h1>
                    <p>
                        <em>Loading...</em>
                    </p>
                </div>
            );
        }
        return (
            <div>
                <div className={this.state.customer.status === 1 ? "customer-view" : "customer-view removed"}>
                    {this.state.customer.status === 1 ? <h1> Customer</h1> : <h1>Removed customer</h1>}
                    <this.Form
                        currencies={this.state.currencies}
                        customer={this.state.customer}
                    />
                    <div className="site-group">
                        <div className="col-md-12">Account manager: {this.state.customer.accountManager && this.state.customer.accountManager.email}</div>
                        <button
                            disabled={this.state.customer.accountManager && authContext.getCachedUser().userName === this.state.customer.accountManager.email}
                            type="button"
                            className="btn btn-primary add-site-button"
                            onClick={() => {
                                this.assignCustomer();
                            }}>
                            Assign customer to me
                        </button>

                        {this.state.modalIsOpen ?
                            <ModalDialog closeModal={this.closeModal} title="Select Account Manager" addButtonText="Assign" addSelected={this.assignToAccountManager}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.accountManagers.map(am => (
                                            <tr
                                                className={this.state.selectedAccountManager.email === am.email ? "selected" : ""}
                                                key={am.userId}
                                                onClick={() => this.onAccountManagerSelected(am)}>
                                                <td>{am.email}</td>
                                                <td>{am.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </ModalDialog> :
                            this.state.accountManagers.length > 0 && (
                                <button
                                    type="button"
                                    className="btn btn-primary add-site-button"
                                    onClick={() => {
                                        this.setState({ modalIsOpen: true });
                                    }}>
                                    Assign to
                                </button>
                            )}
                    </div>
                    <h3>Site groups</h3>
                    <SiteGroupList
                        groups={this.state.customer.siteGroup}
                        siteSelected={this.setNewSiteForGroup}
                        siteRemoved={this.removeSiteFromGroup}
                        onInvoiceIntervalChanged={
                            this.onGroupInvoiceIntervalChanged
                        }
                        onGroupInvoiceDateChanged={
                            this.onGroupInvoiceDateChanged
                        }
                        onGroupInvoicePeriodStartDateChanged={
                            this.onGroupInvoicePeriodStartDateChanged
                        }
                        onGroupPaymentTermChanged={
                            this.onGroupPaymentTermChanged
                        }
                        removeSiteGroup={this.removeSiteGroup}
                    />

                    <div className="site-group-button-name">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                this.newSiteGroup();
                            }}>
                            Add new site group
                        </button>
                    </div>
                </div>

                <ToastContainer />
            </div>
        );
    }

    onAccountManagerSelected(am) {
        this.setState({ selectedAccountManager: am });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    assignToAccountManager() {
        if (this.state.selectedAccountManager) {
            this.closeModal();

            adalApiFetch(fetch, "api/Customers/" + this.state.customer.customerId + "/assign/" + this.state.selectedAccountManager.userId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(function (response) {
                if (response.ok) {
                    return response.json;
                }
                else {
                    throw new Error(
                        "Error: " +
                        response.status +
                        " while assigning customer"
                    );
                }
            }).then(response => {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        accountManager: this.state.selectedAccountManager,
                    },
                    selectedAccountManager: ''
                });
                toast.success("Customer " + this.state.customer.name + " assigned", {
                    autoClose: 3500
                });
            }).catch(function (error) {
                console.log(error);
                toast.error("Failed to assign the customer", {
                    autoClose: 3500,
                });
            });
        }
    }

    saveCustomer(values, { setSubmitting }) {
        if (this.state.customer) {
            this.setState({
                customer: {
                    ...this.state.customer,
                    name: values.name,
                    nameExtension: values.nameExtension,
                    contactEmail: values.contactEmail,
                    contactName: values.contactName,
                    contactPhone: values.contactPhone,
                    streetAddress: values.streetAddress,
                    additionalStreetAddress: values.additionalStreetAddress,
                    city: values.city,
                    postCode: values.postCode,
                    country: values.country,
                    vatnumber: values.vatnumber,
                    currencyId: values.currencyId,
                    paymentTermNetDays: values.paymentTermNetDays,
                    reference: values.reference,
                    customerCode: values.customerCode !== '' ? values.customerCode : null,
                    quickBooksID: values.quickBooksID !== '' ? values.quickBooksID : null,
                    netSuiteID: values.netSuiteID !== '' ? values.netSuiteID : null
                },
            });
        } else {
            this.setState({ customer: values });
        }

        this.save(setSubmitting);
    }

    save(setSubmitting) {
        if (this.state.customer.customerId) {
            const url = "api/Customers/" + this.state.customer.customerId;

            adalApiFetch(fetch, url, {
                method: "PUT",
                body: JSON.stringify(this.state.customer),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(function (response) {
                    setSubmitting(false);
                    if (response.ok) {
                        return null;
                    }
                    else if (response.status === 400) {
                        return response.text();
                    }
                    else {
                        throw new Error(
                            "Error: " +
                            response.status +
                            " while saving customer: " + response.text()
                        );
                    }
                })
                .then(data => {
                    if (data === null) {
                        toast.success("Customer saved successfully", {
                            autoClose: 3500,
                        });
                    }
                    else {
                        throw new Error(data);
                    }
                })
                .catch(function(error) {
                    console.log(error);

                    toast.error("Failed to update customer. " + error, {
                        autoClose: 3500,
                    });
                });
        } else {
            adalApiFetch(fetch, "api/Customers", {
                method: "POST",
                body: JSON.stringify(this.state.customer),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(function (response) {
                    setSubmitting(false);
                    if (response.ok) {
                        return response.json();
                    }
                    else if (response.status === 400) {
                        return response.text();
                    } else {
                        throw new Error(
                            "Error: " +
                            response.status +
                            " while saving customer"
                        );
                    }
                })
                .then(data => {
                    if (typeof data === 'object') {
                        this.setState({ customer: data });

                        toast.success("Customer saved successfully", {
                            autoClose: 3500,
                        });
                    }
                    else {
                        throw new Error(data);
                    }
                })
                .catch(function(error) {
                    console.log(error);

                    toast.error("Failed to add new customer. " + error, {
                        autoClose: 3500,
                    });
                });
        }
    }

    assignCustomer() {
        adalApiFetch(fetch, "api/Customers/" + this.state.customer.customerId + "/assign", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(function (response) {
            if (response.ok) {
                return response.json();
            }
            else if (response.status === 400) {
                return response.text();
            } else {
                throw new Error(
                    "Error: " +
                    response.status +
                    " while assigning customer"
                );
            }
        }).then(response => {

            this.setState({
                customer: {
                    ...this.state.customer,
                    accountManager: response,
                },
            });

            toast.success("Customer " + this.state.customer.name + " assigned", {
                autoClose: 3500
            })
        })
            .catch(function (error) {
                console.log(error);

                toast.error("Failed to assign the customer", {
                    autoClose: 3500,
                });
            });

    }

    updateArrayAtIndex(array, index, fn) {
        array = array.slice();
        array[index] = fn(array[index]); // since we do 1-to-1 map
        return array;
    }

    updateSiteGroup(siteGroupId, fn) {
        const siteGroup = this.state.customer.siteGroup;
        let idx = findIndex(siteGroup, ["siteGroupId", siteGroupId]);

        this.setState({
            customer: {
                ...this.state.customer,
                siteGroup: this.updateArrayAtIndex(siteGroup, idx, fn),
            },
        });
    }

    setNewSiteForGroup(siteGroupId, sites) {
        this.updateSiteGroup(siteGroupId, siteGroup => ({
            ...siteGroup,
            site: [...siteGroup.site, ...sites],
        }));
    }

    removeSiteFromGroup(siteGroupId, site) {
        this.updateSiteGroup(siteGroupId, siteGroup => ({
            ...siteGroup,
            site: pull(siteGroup.site, site),
        }));
    }

    newSiteGroup() {
        let customer = this.state.customer;

        const newGroup = {
            siteGroupId: this.state.newSiteGroupIndex,
            customerId: this.state.customer.customerId,
            nextInvoiceDate: null,
            nextInvoicePeriodStartDate: null,
            invoiceInterval: 1,
            site: [],
            paymentTermNetDays: this.state.customer.paymentTermNetDays,
        };

        const siteGroups = customer.siteGroup ? customer.siteGroup : [];

        this.setState({
            customer: {
                ...customer,
                siteGroup: [...siteGroups, newGroup],
            },
            newSiteGroupIndex: this.state.newSiteGroupIndex - 1,
        });
    }

    removeSiteGroup(siteGroupId) {
        let { customer } = this.state;
        let group = find(customer.siteGroup, ["siteGroupId", siteGroupId]);

        const siteGroups = without(customer.siteGroup, group);

        this.setState({
            customer: {
                ...customer,
                siteGroup: [...siteGroups],
            },
        });
    }

    onGroupInvoiceIntervalChanged(siteGroupId, newValue) {
        this.updateSiteGroup(siteGroupId, siteGroup => ({
            ...siteGroup,
            invoiceInterval: Number(newValue),
        }));
    }

    onGroupInvoiceDateChanged(siteGroupId, newValue) {
        console.log(newValue);
        this.updateSiteGroup(siteGroupId, siteGroup => ({
            ...siteGroup,
            nextInvoiceDate: newValue,
        }));
    }

    onGroupInvoicePeriodStartDateChanged(siteGroupId, newValue) {
        console.log(newValue);
        this.updateSiteGroup(siteGroupId, siteGroup => ({
            ...siteGroup,
            nextInvoicePeriodStartDate: newValue
        }));
    }

    onGroupPaymentTermChanged(siteGroupId, newValue) {
        this.updateSiteGroup(siteGroupId, siteGroup => ({
            ...siteGroup,
            paymentTermNetDays: Number(newValue),
        }));
    }
}
