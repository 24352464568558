import React from "react";

import { countries } from "../../utils/countries";
import { paymentTerms } from "../../utils/arrays";

const ValueLabel = props => {
    return (
        <label htmlFor={props.htmlItem} style={{ display: "block" }}>
            {props.name}
        </label>
    );
};

const InputField = props => {
    return (
        <input
            id={props.id}
            placeholder={props.placeHolder}
            type={props.type}
            value={props.value}
            onChange={props.change}
            onBlur={props.blur}
            className="form-control"
        />
    );
};

export default function CustomerForm(props) {
    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset
    } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="customer-form">
                <div className="form-group">
                    <ValueLabel name="Customer name" htmlItem="name" />
                    <input
                        id="name"
                        placeholder="Customer name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        autoFocus
                    />
                    {errors.name &&
                        touched.name && (
                            <div className="input-feedback">{errors.name}</div>
                        )}
                </div>
                <div className="form-group">
                    <ValueLabel name="Customer name extension" htmlItem="nameExtension" />
                    <input
                        id="nameExtension"
                        placeholder="Customer name extension"
                        type="text"
                        value={values.nameExtension}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                    />
                    {errors.nameExtension &&
                        touched.nameExtension && (
                            <div className="input-feedback">{errors.nameExtension}</div>
                        )}
                </div>
                <div className="form-group">
                    <ValueLabel name="Invoicing email address" htmlItem="contactEmail" />
                    <InputField
                        id="contactEmail"
                        placeHolder="Enter customer invoicing email address"
                        type="text"
                        value={values.contactEmail}
                        change={handleChange}
                        blur={handleBlur}
                    />
                    {errors.contactEmail &&
                        touched.contactEmail && (
                            <div className="input-feedback">
                                {errors.contactEmail}
                            </div>
                        )}
                </div>
                <div className="form-group">
                    <ValueLabel name="Contact name" htmlItem="contactName" />
                    <InputField
                        id="contactName"
                        placeHolder="Contact name"
                        type="text"
                        value={values.contactName}
                        change={handleChange}
                        blur={handleBlur}
                    />
                    {errors.contactName &&
                        touched.contactName && (
                            <div className="input-feedback">
                                {errors.contactName}
                            </div>
                        )}
                </div>
                <div className="form-group">
                    <ValueLabel name="Contact phone" htmlItem="contactPhone" />
                    <InputField
                        id="contactPhone"
                        placeHolder="Contact phone"
                        type="tel"
                        value={values.contactPhone}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <ValueLabel
                        name="Street address"
                        htmlItem="streetAddress"
                    />
                    <InputField
                        id="streetAddress"
                        placeHolder="Street address"
                        type="text"
                        value={values.streetAddress}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <ValueLabel
                        name="Additional street address"
                        htmlItem="additionalStreetAddress"
                    />
                    <InputField
                        id="additionalStreetAddress"
                        placeHolder="Additional street address"
                        type="text"
                        value={values.additionalStreetAddress}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <ValueLabel name="City" htmlItem="city" />
                    <InputField
                        id="city"
                        placeHolder="City"
                        type="text"
                        value={values.city}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <ValueLabel name="Postcode" htmlItem="postCode" />
                    <InputField
                        id="postCode"
                        placeHolder="Postcode"
                        type="text"
                        value={values.postCode}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>

                <div className="form-group">
                    <ValueLabel name="Country" htmlItem="country" />
                    <select
                        id="country"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                    >
                        {countries.map(country => (
                            <option key={country.code} value={country.code}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <ValueLabel name="VAT number" htmlItem="vatnumber" />
                    <InputField
                        id="vatnumber"
                        placeHolder="VAT number"
                        type="text"
                        value={values.vatnumber}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <ValueLabel name="Currency" htmlItem="currencyId" />
                    <select
                        id="currencyId"
                        name="currencyId"
                        value={values.currencyId}
                        onChange={handleChange}
                    >
                        <option key="0" value="" />
                        {props.currencies.map(currency => (
                            <option
                                key={currency.currencyId}
                                value={currency.currencyId}
                            >
                                {currency.currencyName}
                            </option>
                        ))}
                    </select>
                    {errors.currencyId &&
                        touched.currencyId && (
                            <div className="input-feedback">
                                {errors.currencyId}
                            </div>
                        )}
                </div>

                <div className="form-group">
                    <ValueLabel name="Reference" htmlItem="reference" />
                    <InputField
                        id="reference"
                        placeHolder="Reference"
                        type="text"
                        value={values.reference}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>

                <div className="form-group">
                    <ValueLabel name="Payment term" htmlItem="paymentTermNetDays" />
                    <select
                        id="paymentTermNetDays"
                        name="paymentTermNetDays"
                        value={values.paymentTermNetDays}
                        onChange={handleChange}
                    >
                        {paymentTerms.map(value => (
                            <option
                                key={value}
                                value={value}
                            >
                                {value} days net
                            </option>
                        ))}
                    </select>
                    {errors.paymentTermNetDays &&
                        touched.paymentTermNetDays && (
                            <div className="input-feedback">
                                {errors.paymentTermNetDays}
                            </div>
                        )}
                </div>
                <div className="form-group">
                    <ValueLabel name="Netvisor customer code" htmlItem="customerCode" />
                    <input
                        id="customerCode"
                        placeholder="Customer code (0XXXX, leave empty if unknown)"
                        type="text"
                        value={values.customerCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        disabled={/0[0-9]{4}/.test(values.customerCode)}
                        autoFocus
                    />
                    {errors.customerCode &&
                        touched.customerCode && (
                            <div className="input-feedback">{errors.customerCode}</div>
                        )}
                </div>
                <div className="form-group">
                    <ValueLabel name="QuickBooks ID" htmlItem="quickBooksID" />
                    <InputField
                        id="quickBooksID"
                        placeHolder="QuickBooks identifier (leave empty if unknown)"
                        type="text"
                        value={values.quickBooksID}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>
                <div className="form-group">
                    <ValueLabel name="NetSuite ID" htmlItem="netSuiteID" />
                    <InputField
                        id="netSuiteID"
                        placeHolder="NetSuite identifier (leave empty if unknown)"
                        type="text"
                        value={values.netSuiteID}
                        change={handleChange}
                        blur={handleBlur}
                    />
                </div>

                <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                >
                    Reset
                </button>
                <button
                    type="submit"
                    className="btn btn-success customer-button"
                    disabled={isSubmitting}
                >
                    Save
                </button>
            </div>
        </form>
    );
}
